// /project/[id]
export const projectTabs = [
  {
    slot: "company_universe",
    label: "Company Universe",
    icon: "i-heroicons-building-office-2",
  },
  {
    slot: "institution_universe",
    label: "Institutions",
    icon: "i-heroicons-building-office",
  },
  {
    slot: "documents",
    label: "Documents",
    icon: "i-heroicons-paper-clip",
  },
  {
    slot: "deliverables",
    label: "Deliverables",
    icon: "i-heroicons-list-bullet-solid",
  },
  {
    slot: "tasks",
    label: "Tasks",
    icon: "i-heroicons-clipboard-document-list",
  },
  {
    slot: "project_history",
    label: "Project History",
    icon: "i-heroicons-calendar-days",
  },
];

// lists/[id]/taskmodal
export const taskModalTabs = [
  {
    slot: "view_tasks",
    label: "View Tasks",
    icon: "i-heroicons-list-bullet",
  },
  {
    slot: "create_task",
    label: "Create Task",
    icon: "i-heroicons-plus",
  },
];

// /companies/[id]
export const publicCompanyTabs = [
  {
    slot: "overview",
    label: "Overview",
    // icon: "i-heroicons-currency-dollar",
  },
  {
    slot: "event_history",
    label: "Event history",
    // icon: "i-heroicons-folder-open",
  },
  {
    slot: "deals",
    label: "Deals",
    // icon: "i-heroicons-paper-clip",
  },
  {
    slot: "investors",
    label: "Investors",
    // icon: "i-heroicons-calendar-days",
  },
  {
    slot: "documents",
    label: "Documents",
  },
  {
    slot: "price_history",
    label: "Price History",
  },
  {
    label: "Tasks",
    slot: "tasks",
  },
];

// /companies/[id]
export const privateCompanyTabs = [
  {
    slot: "overview",
    label: "Overview",
    // icon: "i-heroicons-currency-dollar",
  },
  {
    slot: "event_history",
    label: "Event history",
    // icon: "i-heroicons-folder-open",
  },
  {
    slot: "deals",
    label: "Deals",
    // icon: "i-heroicons-paper-clip",
  },
  {
    slot: "investors",
    label: "Investors",
    // icon: "i-heroicons-calendar-days",
  },
  {
    slot: "documents",
    label: "Documents",
  },
  {
    label: "Tasks",
    slot: "tasks",
  },
];

// /deals/[id]
export const dealTabs = [
  {
    slot: "overview",
    label: "Overview",
  },
  {
    slot: "prospects_individual",
    label: "Individual Prospects",
  },
  {
    slot: "prospects_institutional",
    label: "Institutional Prospects",
  },
  {
    slot: "investors",
    label: "Investors",
  },
  {
    slot: "documents",
    label: "Documents",
  },
  {
    slot: "pro-rata_allocations",
    label: "Pro Rata Allocations",
  },
];

// /projects/[id]/InstitutionalUniverse
export const projectInstitutionalTabs = [
  {
    slot: "contacts",
    label: "Contacts",
  },
  {
    slot: "portfolio",
    label: "Portfolio",
  },
];

// /deals/[id]/prospectsInstitution
export const dealProspectsInstitutionalTabs = [
  {
    slot: "contacts",
    label: "Contacts",
  },
  {
    slot: "portfolio",
    label: "Portfolio",
  },
];

// /institution/[id]
export const institutionTabs = [
  {
    slot: "overview",
    label: "Overview",
    // icon: "i-heroicons-currency-dollar",
  },
  {
    slot: "event_history",
    label: "Event history",
    // icon: "i-heroicons-folder-open",
  },
  {
    slot: "investments",
    label: "Investments",
    // icon: "i-heroicons-paper-clip",
  },
  {
    slot: "documents",
    label: "Documents",
    // icon: "i-heroicons-paper-clip",
  },
];

// /users/[id]
export const userTabs = [
  {
    slot: "overview",
    label: "Overview",
    // icon: "i-heroicons-currency-dollar",
  },
  {
    slot: "investments",
    label: "Investments",
    // icon: "i-heroicons-folder-open",
  },
  {
    slot: "referrals",
    label: "Referrals",
    // icon: "i-heroicons-paper-clip",
  },
  {
    slot: "documents",
    label: "Documents",
    // icon: "i-heroicons-calendar-days",
  },
  {
    slot: "suitabilityReview",
    label: "Suitability Review",
  },
  {
    slot: "aml",
    label: "Anti Money Laundering",
  },
  {
    slot: "institutions",
    label: "Institutions",
  },
];

// /internalUsers/[id]
export const registeredRepTabs = [
  {
    slot: "overview",
    label: "Overview",
    // icon: "i-heroicons-currency-dollar",
  },
  {
    slot: "licenses",
    label: "FINRA Profile",
    // icon: "i-heroicons-folder-open",
  },
  {
    slot: "documents",
    label: "Documents",
    // icon: "i-heroicons-calendar-days",
  },
  {
    slot: "gifts",
    label: "Gift Disclosures",
  },
  {
    slot: "expenses",
    label: "Personal Expenses",
  },
  {
    slot: "accounts",
    label: "Brokerage Accounts",
  },
];

// /deals/[id]/edit
export const dealEditTabs = [
  {
    slot: "general",
    label: "General",
    // icon: "i-heroicons-currency-dollar",
  },
  {
    slot: "terms",
    label: "Terms",
    // icon: "i-heroicons-folder-open",
  },
  {
    slot: "admin",
    label: "Admin Controls",
  },
];

// /users/[id]/edit
export const userEditTabs = [
  {
    slot: "general",
    label: "General",
    // icon: "i-heroicons-currency-dollar",
  },
  {
    slot: "contact",
    label: "Contact Information",
    // icon: "i-heroicons-currency-dollar",
  },
  {
    slot: "kyc",
    label: "KYC and Suitability",
    // icon: "i-heroicons-folder-open",
  },
  {
    slot: "roles",
    label: "Roles",
  },
];

// /admin/compliance/restrictedLists
export const restrictedListsTabs = [
  {
    slot: "users",
    label: "DNC list",
  },
  {
    slot: "companies",
    label: "Company watch list",
  },
];

// /admin/compliance/amlResources
export const amlResourcesTabs = [
  {
    slot: "customers",
    label: "Customer Stats",
  },
  {
    slot: "ofacChecks",
    label: "OFAC Checks",
  },
  {
    slot: "fcpa",
    label: "FCPA",
  },
];
